// @ts-check
import { format, isPast, subHours, isEqual, isAfter } from 'date-fns';
import { firebase } from '@planity/datastores';
import {
	invokeLambda,
	computeFees,
	formatPrice,
	sanitizeName,
	totalAppointmentPrice,
	isStripeError,
	getFinalFees,
	IS_DEPOSIT,
	IS_PREPAYMENT,
	IS_ONLINE_PAYMENT,
	PAYMENT_TYPE_CARD,
	PAYMENT_METHOD,
	getExpiredCards
} from '@planity/helpers';
import { withLocalization } from '@planity/localization';
import React, { Component } from 'react';
import {
	Localize,
	withStripeElementsConsumer,
	BookAppointmentTitle,
	StripePayment
} from '@planity/components';
import credentials from '@planity/credentials';
import { PaymentChoice } from './payment_choice';
import { CurePaymentChoice } from './cure_payment_choice';
import { withStripeFees } from '../../app_settings/stripeFeesProvider';
import classNames from 'classnames/bind';
import withStyles from 'isomorphic-style-loader/withStyles';
import styles from './appointment_payment.modules.scss';
import { Banner, Spinner } from '@planity/ui';
import { Confirmation } from '../../book_appointment/confirmation';
import { Policies } from './policies';
import { GiftVoucherPayment } from './gift_voucher_payment';
import { noop } from '@planity/helpers';

const {
	ENABLE_USER_PAYS_FEES,
	USE_GIFT_VOUCHER_FOR_PAYMENTS_ENABLED,
	USE_CURE_FOR_PAYMENTS_ENABLED
} = credentials;
const PARTIAL_DEPOSIT = 'partialDeposit';
const FULL_DEPOSIT_AND_PREPAYMENT = 'fullDepositAndPrepayment';
const CLASSIC_ONLINE_PAYMENT = 'classicOnlinePayment';

const LAMBDA_VALIDATE_GIFT_VOUCHER_FOR_PAYMENT =
	'validateGiftVoucherForPayment';

const GIFT_VOUCHER_INITIAL_STATE = {
	giftVoucherNumber: '',
	amount: 0,
	isValidated: false,
	isLoading: false,
	error: false,
	errorMessage: ''
};

const GiftVoucherValidationError = {
	UNKNOWN_NUMBER: 'UNKNOWN_NUMBER', // the provided gift voucher number does not exist
	IS_EXPIRED: 'IS_EXPIRED', // this gift voucher is expired
	EXPIRES_BEFORE_APPOINTMENT: 'EXPIRES_BEFORE_APPOINTMENT', // this gift voucher expires before appointment starts
	AMOUNT: 'AMOUNT', // available amount is lower than 1,00€
	SCOPE: 'SCOPE', // this gift voucher can't be used for appointments
	TOO_MANY_ATTEMPTS: 'TOO_MANY_ATTEMPTS' // the user failed at least 10 times to book with cc in a business
};

class OnlinePayment extends Component {
	constructor(props) {
		super(props);
		this.state = {
			user: null,
			cardIsAdding: false,
			stripeError: null,
			displayAddCard: false,
			paymentMethod: props.paymentMethod,
			giftVoucher: GIFT_VOUCHER_INITIAL_STATE
		};
	}

	componentDidMount() {
		const { user } = this.props;
		this.setState({
			user: {
				...user,
				email: firebase.auth().currentUser
					? firebase.auth().currentUser.email
					: null
			}
		});

		if (this.props.paymentMethods && this.props.paymentMethods.length === 0) {
			this.setState({ displayAddCard: true });
		}
	}

	componentDidUpdate(prevProps, prevState) {
		const {
			paymentMethods,
			redirectTokenError,
			status,
			defaultPaymentMethod,
			eligibleCures,
			giftVoucherPaymentInfo,
			moveAppointment,
			paymentMethod
		} = this.props;

		if (this.state.paymentMethod !== this.props.paymentMethod) {
			this.setState({ ...this.state, paymentMethod: this.props.paymentMethod });
		}

		const isNotDepositPayment = [IS_PREPAYMENT, IS_ONLINE_PAYMENT].includes(
			status
		);

		const { giftVoucher } = this.state;
		const { isValidated, giftVoucherNumber, amount } =
			giftVoucherPaymentInfo || {};

		const isGiftVoucherValidated = isValidated;
		const hasGiftVoucherNumber = giftVoucherNumber;
		const isUsingGiftVoucher =
			paymentMethod === PAYMENT_METHOD.USE_GIFT_VOUCHER;
		const isGiftVoucherNumberChanged =
			giftVoucher.giftVoucherNumber !== giftVoucherNumber;
		const isGiftVoucherPaymentInfoChanged =
			prevProps.giftVoucherPaymentInfo !== giftVoucherPaymentInfo;

		if (
			moveAppointment &&
			isGiftVoucherValidated &&
			hasGiftVoucherNumber &&
			isUsingGiftVoucher &&
			isGiftVoucherNumberChanged &&
			isGiftVoucherPaymentInfoChanged
		) {
			this.setState({
				...this.state,
				giftVoucher: {
					isValidated,
					giftVoucherNumber,
					amount,
					error: false,
					errorMessage: '',
					isLoading: false
				}
			});
		}

		if (!prevProps.redirectTokenError && !!redirectTokenError) {
			this.props.setError(redirectTokenError);
		} else if (!!prevProps.redirectTokenError && !redirectTokenError) {
			this.props.setError(null);
		}

		if (!prevProps.paymentMethods && paymentMethods?.length === 0) {
			this.setState({ displayAddCard: true });
		}

		// if user is NOT buying cc (e.g. booking appointment)
		// if the date of the appointment changed, reset gift voucher state
		if (
			!this.props.isGiftVoucher &&
			this.props.appointment.date !== prevProps.appointment.date
		) {
			this.setState({ ...this.state, giftVoucher: GIFT_VOUCHER_INITIAL_STATE });
		}

		if (
			this.props.paymentMethod !== PAYMENT_METHOD.USE_GIFT_VOUCHER &&
			prevProps.paymentMethod !== this.props.paymentMethod
		) {
			this.setState({ ...this.state, giftVoucher: GIFT_VOUCHER_INITIAL_STATE });
		}

		// unselect selected payment method if USE CURE with IS_PREPAYMENT or IS_ONLINE_PAYMENT
		if (
			isNotDepositPayment &&
			this.props.paymentMethod === PAYMENT_METHOD.USE_CURE &&
			this.props.selectedPaymentMethod
		) {
			this.props.setSelectedPaymentMethod(null);
		}

		// Reset selected default payment method if no cures or gift voucher not validated
		if (
			paymentMethods?.length > 0 &&
			!this.props.selectedPaymentMethod &&
			eligibleCures !== prevProps.eligibleCures &&
			this.props.paymentMethod !== PAYMENT_METHOD.USE_GIFT_VOUCHER &&
			this.props.paymentMethod !== PAYMENT_METHOD.USE_CURE &&
			(eligibleCures.length === 0 ||
				(moveAppointment && !giftVoucherPaymentInfo?.isValidated))
		) {
			const expiredCards = getExpiredCards(paymentMethods);
			const defaultPaymentMethodToSelect = paymentMethods.find(
				paymentMethod =>
					paymentMethod.id === defaultPaymentMethod &&
					!(defaultPaymentMethod in expiredCards)
			);

			if (defaultPaymentMethodToSelect) {
				this.props.setSelectedPaymentMethod(defaultPaymentMethodToSelect);
			}
		}
		if (
			(paymentMethod === PAYMENT_METHOD.USE_CURE ||
				paymentMethod === PAYMENT_METHOD.USE_GIFT_VOUCHER) &&
			this.state.displayAddCard
		) {
			this.setState({
				...this.state,
				selectedPaymentMethod: null,
				displayAddCard: false
			});
		}
	}

	render() {
		const {
			appointment,
			cureUsedForAppointment,
			isCureExpirationValidForAppointment,
			giftVoucherPaymentInfo,
			onlinePaymentServices,
			business,
			businessId,
			childId,
			childName,
			customerID,
			clientSecret,
			formatAppointmentForLambda,
			formattedSteps,
			isGiftVoucher,
			location,
			title,
			paymentContainerRef,
			status,
			paymentMethod,
			paymentMethodIdToDelete,
			setPaymentMethod = noop,
			allStripeFees,
			isOnline,
			completedSteps,
			setError,
			setIsLoading,
			resetPaymentIntent,
			paymentCreatedDateToDelete,
			moveAppointment,
			totalPriceToDelete,
			isPrePaymentToDelete,
			isDark,
			paymentMethodType,
			selectedPaymentMethod,
			doesSelectedPaymentMethodNeedConsent,
			hasAcceptedSaveCard,
			locale,
			isSelectedPaymentLinkedToActiveAppointments,
			allServicesAreCoveredByCures,
			eligibleCures,
			serviceIdsWithEligibleCures,
			dateLocale
		} = this.props;

		const date = !isGiftVoucher ? appointment.date : new Date();
		if (!date) return null;

		const classes = classNames.bind(styles);

		const past = isPast(
			subHours(date, business.settings.onlinePayment.lateCancellationDelay)
		);

		const userPaysFees = !!business?.settings?.onlinePayment?.userPaysFees;

		const stripeFees = getFinalFees(
			allStripeFees,
			business?.countryCode,
			paymentMethodType
		);

		const {
			totalPrice,
			totalPriceWithoutFees,
			depositPrice,
			depositPriceWithoutFees,
			depositRest,
			depositRateToApply
		} = totalAppointmentPrice(
			onlinePaymentServices,
			userPaysFees && ENABLE_USER_PAYS_FEES,
			stripeFees
		);

		const isPrePayment =
			status === IS_PREPAYMENT ||
			(status === IS_DEPOSIT &&
				(paymentMethod === PAYMENT_METHOD.PAY_ALL ||
					depositRateToApply === 100));
		const hasDepositActivated =
			status === IS_DEPOSIT && depositRateToApply < 100;
		const isDeposit =
			status === IS_DEPOSIT &&
			paymentMethod === PAYMENT_METHOD.PAY_DEPOSIT &&
			depositRateToApply < 100;
		const isPrepaymentOrDeposit = !!isDeposit || !!isPrePayment;

		const cancelFee = !isGiftVoucher
			? this.calculateCancelFee(isPrepaymentOrDeposit, userPaysFees, stripeFees)
			: null;
		const noShowFee = !isGiftVoucher
			? this.calculateNoShowFee(isPrepaymentOrDeposit, userPaysFees, stripeFees)
			: null;

		const fees = userPaysFees
			? computeFees({
					amount: isDeposit ? depositPriceWithoutFees : totalPriceWithoutFees,
					stripeFees
			  })
			: 0;

		// need to compute price without fees + fees because fees change in deposit mode
		const totalPriceWithFees = formatPrice(totalPriceWithoutFees + fees, true);

		const parsedPrice = price => parseFloat(price.replace(',', '.'));
		const hasSameAmount = isPrePaymentToDelete
			? isDeposit
				? parsedPrice(depositPrice) === parsedPrice(totalPriceToDelete)
				: parsedPrice(totalPrice) === parsedPrice(totalPriceToDelete)
			: false;

		const stripePaymentAmount = this.props.isGiftVoucher
			? this.props.totalPriceInCents
			: (isDeposit ? depositPriceWithoutFees : totalPriceWithoutFees) + fees;

		const stripePaymentAmountWithoutFees = this.props.isGiftVoucher
			? this.props.totalPriceInCents
			: isDeposit
			? depositPriceWithoutFees
			: totalPriceWithoutFees;

		const isVisiblePayment = !isGiftVoucher
			? !!business?.modules?.onlinePayment &&
			  !!business?.settings?.onlinePayment &&
			  isOnline
			: !!business?.settings?.onlinePayment; // online payment can be deactivated but stripe onboarding has been done

		const redirectClientSecret =
			!this.props.isRedirectRestartingBooking && location?.state?.clientSecret;

		const isPaymentIntent = isPrepaymentOrDeposit || isGiftVoucher;

		const hasPassedCardConsentRequirement =
			!!selectedPaymentMethod &&
			(!isPaymentIntent || // imprint payment
				!doesSelectedPaymentMethodNeedConsent || // we don't need consent verification -> see the function .does_payment_method_need_consent.js
				isSelectedPaymentLinkedToActiveAppointments || // the voucher is still linked to future appointments (with imprint)
				hasAcceptedSaveCard !== null); // the user has replied to consent verification

		// wether a payment method choice should be provided to the user or not
		const displayDepositPaymentChoice =
			hasDepositActivated &&
			(!moveAppointment || (moveAppointment && !hasSameAmount));

		const useGiftVoucher = paymentMethod === PAYMENT_METHOD.USE_GIFT_VOUCHER;
		const useCure =
			paymentMethod === PAYMENT_METHOD.USE_CURE &&
			serviceIdsWithEligibleCures.length > 0;
		const { totalPriceWithEligibleCures } = totalAppointmentPrice(
			onlinePaymentServices,
			false,
			null,
			serviceIdsWithEligibleCures
		);
		const isAppointmentValidForCureExpiration =
			moveAppointment &&
			isCureExpirationValidForAppointment &&
			!giftVoucherPaymentInfo?.giftVoucherNumber &&
			useCure;
		const isEmpreinteWithValidCure =
			isAppointmentValidForCureExpiration && !isPrepaymentOrDeposit;
		const isPrepaymentWithValidCure =
			isAppointmentValidForCureExpiration && isPrepaymentOrDeposit;

		const isGiftVoucherValidForMoveAppointmentWithPrepaymentOrDeposit =
			moveAppointment &&
			giftVoucherPaymentInfo?.isValidated &&
			giftVoucherPaymentInfo?.giftVoucherNumber &&
			useGiftVoucher &&
			(status === IS_PREPAYMENT || status === IS_DEPOSIT);

		const isMoveAppointmentValidWithoutPrepaymentOrDeposit =
			!isGiftVoucherValidForMoveAppointmentWithPrepaymentOrDeposit &&
			(!isPrepaymentWithValidCure || isEmpreinteWithValidCure);

		const isPaymentMethodMoveError =
			!isPrePaymentToDelete &&
			!isGiftVoucherValidForMoveAppointmentWithPrepaymentOrDeposit &&
			paymentMethod !== PAYMENT_METHOD.USE_CURE &&
			moveAppointment &&
			!!(isPrePayment || isDeposit);

		const isPrepaymentOrPaymentStatus = [
			IS_PREPAYMENT,
			IS_ONLINE_PAYMENT
		].includes(status);

		const isPayAllOrDepositMethod = [
			PAYMENT_METHOD.PAY_ALL,
			PAYMENT_METHOD.PAY_DEPOSIT
		].includes(paymentMethod);

		const usePrePaymentWithCure =
			USE_CURE_FOR_PAYMENTS_ENABLED &&
			serviceIdsWithEligibleCures?.length > 0 &&
			isPrepaymentOrPaymentStatus;

		const showCancellationPolicies =
			!isGiftVoucher && !useGiftVoucher && !useCure;

		// used to display the gift voucher input, which should be displayed if :
		// 1) the flag USE_GIFT_VOUCHER_FOR_PAYMENTS_ENABLED is enabled
		// 2) the current transaction is not about a  product or a gift voucher
		// 3) the current transaction is either :
		// - a deposit (isDeposit === true), when gift voucher payment method is selected
		// - a prepayment or a card fingerprint (isDeposit === false)
		const displayGiftVoucherInput =
			USE_GIFT_VOUCHER_FOR_PAYMENTS_ENABLED &&
			!isGiftVoucher &&
			(!displayDepositPaymentChoice ||
				(displayDepositPaymentChoice && useGiftVoucher));

		// used to adapt the confirmation button label, when the gift voucher input is visible and :
		// - the user selected gift voucher payment method, in case of a deposit
		// - or, the selected voucher is null (i.e. !this.props.selectedPaymentMethod)
		//   (i.e. gift voucher payment is selected), in case of prepayment or card fingerprint
		const payWithGiftVoucher =
			(displayDepositPaymentChoice && useGiftVoucher) ||
			(!displayDepositPaymentChoice && !this.props.selectedPaymentMethod);
		const displayFees =
			!isGiftVoucher && !useCure && ENABLE_USER_PAYS_FEES && userPaysFees;

		// wether the confirmation button should be enabled ot not
		const isSubmitEnabled = () => {
			if (useCure) {
				return true;
			}

			if (payWithGiftVoucher) {
				return this.state.giftVoucher.isValidated;
			}

			return (
				(moveAppointment && hasSameAmount) ||
				paymentMethodType !== PAYMENT_TYPE_CARD ||
				hasPassedCardConsentRequirement
			);
		};

		const renderPaymentConfirmationButton = (
			<div className={classes({ payment: true })}>
				<hr className={styles.simpleHr} />
				<Confirmation
					appointment={appointment}
					completedSteps={completedSteps || []}
					confirm={() => this.confirm(hasSameAmount)}
					cureUsedForAppointment={cureUsedForAppointment}
					depositPrice={depositPrice}
					giftVoucherPaymentInfo={giftVoucherPaymentInfo}
					hasSameAmount={hasSameAmount}
					isDeposit={isDeposit}
					isDisabled={!isSubmitEnabled()}
					isGiftVoucher={isGiftVoucher}
					isLoading={this.props.isLoading}
					isOnline={isOnline}
					isPending={this.props.isPending}
					isPrePayment={isPrepaymentOrDeposit}
					isVisiblePayment={isVisiblePayment}
					moveAppointment={this.props.moveAppointment}
					payWithCure={useCure}
					payWithGiftVoucher={payWithGiftVoucher}
					totalPrice={this.props.totalPrice || totalPrice || 0}
					userId={this.props.userId}
					wasBooked={this.props.wasBooked}
				/>
			</div>
		);

		// little helper so gift voucher state updates escape verbosity
		const setGiftVoucherState = updatedGiftVoucherState => {
			this.setState({
				...this.state,
				giftVoucher: { ...this.state.giftVoucher, ...updatedGiftVoucherState }
			});
		};

		const handleGiftVoucherInputFocus = () => {
			// unselect voucher payment method
			if (setPaymentMethod) {
				setPaymentMethod(PAYMENT_METHOD.USE_GIFT_VOUCHER);
			}
			this.setState({ displayAddCard: false });

			if (!displayDepositPaymentChoice) {
				this.props.setSelectedPaymentMethod(null);
			}
		};

		// triggered when the user updates gift voucher number input
		const handleGiftVoucherInputChange = giftVoucherNumber => {
			setGiftVoucherState({
				giftVoucherNumber,
				error: false
			});
		};

		const handleCureFocus = () => {
			// unselect voucher payment method
			this.props.setPaymentMethod(PAYMENT_METHOD.USE_CURE);
			this.props.setSelectedPaymentMethod(null);
			this.setState({ ...this.state, displayAddCard: false });
		};

		const getGiftVoucherErrorMessage = (error, expirationDate) => {
			if (error in GiftVoucherValidationError) {
				return (
					<Localize
						args={
							expirationDate
								? {
										expirationDate: format(new Date(expirationDate), 'PPPP', {
											locale: dateLocale
										})
								  }
								: {}
						}
						text={`onlinePayment.giftVoucher.error.${error}`}
					/>
				);
			} else {
				console.error(`Unknown gift voucher error: ${error}`);
				return '';
			}
		};

		// triggered when the user requests gift voucher validation
		const handleGiftVoucherValidation = async () => {
			try {
				setGiftVoucherState({
					isLoading: true
				});

				const userToken = await firebase.auth().currentUser.getIdToken();

				const giftVoucherNumber = parseInt(
					this.state.giftVoucher.giftVoucherNumber
				);

				const response = await invokeLambda(
					LAMBDA_VALIDATE_GIFT_VOUCHER_FOR_PAYMENT,
					{
						businessId: businessId,
						giftVoucherNumber: giftVoucherNumber,
						appointmentStart: Date.parse(appointment.date),
						firebaseToken: userToken
					}
				);

				if (response?.error || !response?.amount) {
					// the gift voucher is unknown or invalid
					setGiftVoucherState({
						isValidated: false,
						error: true,
						errorMessage: getGiftVoucherErrorMessage(
							response?.error,
							response?.expires
						),
						isLoading: false
					});
				} else {
					// the gift voucher is valid
					setGiftVoucherState({
						isValidated: true,
						amount: response.amount,
						error: false,
						errorMessage: '',
						isLoading: false
					});
				}
			} catch (e) {
				console.error('An error occured while validating the gift voucher', e);
				setError('GENERIC_ERROR');
				// set error state
				setGiftVoucherState({
					isValidated: false,
					error: true,
					errorMessage: '',
					isLoading: false
				});
			}
		};

		// triggered when the user clears gift voucher input
		const handleGiftVoucherDeletion = () => {
			// reset gift voucher input state
			setGiftVoucherState(GIFT_VOUCHER_INITIAL_STATE);
		};

		return (
			<div className={classes({ payment: true })} ref={paymentContainerRef}>
				<BookAppointmentTitle
					index={isGiftVoucher ? '5. ' : '4. '}
					isDark={isDark}
					text={title ? title : 'bookAppointment.onlinePaymentTitle'}
				/>
				{this.state.user && customerID ? (
					<div className={`${styles.card} planity_online_payment_wrapper`}>
						{isPrePaymentToDelete && moveAppointment && !hasSameAmount && (
							<div className={styles.errorMessage}>
								<Localize
									args={{
										date: paymentCreatedDateToDelete,
										price: totalPriceToDelete
									}}
									text={'bookAppointment.errors.MOVED_WITH_REFUND'}
								/>
							</div>
						)}
						{/* moveAppointment */}
						{isPaymentMethodMoveError && (
							<div className={styles.errorMessage}>
								<Localize
									args={{
										businessName: business?.name || '',
										price: totalPrice
									}}
									text={
										paymentMethodIdToDelete
											? 'bookAppointment.errors.MOVED_FROM_IMPRINT_TO_PREPAYMENT'
											: 'bookAppointment.errors.MOVED_FROM_NO_ONLINE_PAYMENT_TO_PREPAYMENT'
									}
								/>
							</div>
						)}
						{/* moveAppointment with cure expired */}
						{moveAppointment &&
							eligibleCures.length === 0 &&
							cureUsedForAppointment &&
							!isCureExpirationValidForAppointment && (
								<div className={styles.errorMessage}>
									<Localize
										args={{
											expirationDate: format(
												new Date(cureUsedForAppointment.expires),
												'PPPP',
												{
													locale: dateLocale
												}
											)
										}}
										text={'bookAppointment.errors.CURE.EXPIRED'}
									/>
								</div>
							)}
						{/* moveAppointment with giftVoucher expired */}
						{moveAppointment &&
							giftVoucherPaymentInfo &&
							!giftVoucherPaymentInfo.isValidated &&
							giftVoucherPaymentInfo.expires && (
								<div className={styles.errorMessage}>
									<Localize
										args={{
											expirationDate: format(
												new Date(giftVoucherPaymentInfo.expires),
												'PPPP',
												{
													locale: dateLocale
												}
											)
										}}
										text={'bookAppointment.errors.GIFTVOUCHER.EXPIRED'}
									/>
								</div>
							)}
						{displayDepositPaymentChoice &&
							!isAppointmentValidForCureExpiration &&
							!isGiftVoucherValidForMoveAppointmentWithPrepaymentOrDeposit && (
								<>
									<PaymentChoice
										allServicesAreCoveredByCures={allServicesAreCoveredByCures}
										depositAmount={depositPrice}
										depositRest={depositRest}
										eligibleCures={this.getUniqueFirstCures(eligibleCures)}
										isLoading={
											isPayAllOrDepositMethod
												? this.props.isLoading || !clientSecret
												: this.props.isLoading
										}
										paymentMethod={paymentMethod}
										setPaymentMethod={setPaymentMethod}
										totalAmount={totalPrice}
									/>
									<hr />
								</>
							)}
						<div className={styles.table}>
							{onlinePaymentServices.isOnline.map(({ name, id, prices }) => {
								if (!prices) return <div key={id} />;
								const selectedCure = eligibleCures?.find(
									eligibleCure => eligibleCure.serviceId === id
								);
								const isEligibleCure =
									useCure &&
									(displayDepositPaymentChoice || usePrePaymentWithCure) &&
									USE_CURE_FOR_PAYMENTS_ENABLED &&
									selectedCure;
								const price = isEligibleCure
									? 0
									: prices.default || prices.min || prices.max || 0;

								return (
									<>
										<div key={id}>
											<span>
												{sanitizeName(name)}
												{isEligibleCure && (
													<span className={styles.eligibleCureLabel}>
														<Localize
															text={'myAccount.cures.eligibleCureLabel'}
														/>
													</span>
												)}
											</span>
											<span className={styles.price}>
												{formatPrice(price, true)}
											</span>
										</div>
										{isEligibleCure &&
											usePrePaymentWithCure &&
											selectedCure && (
												<div>
													{selectedCure.cures[0].cureName} {'-'}{' '}
													<Localize text='myAccount.cures.remainingQuantity.title' />{' '}
													<Localize
														args={{
															originalQuantity:
																selectedCure.cures[0].originalQuantity,
															quantity: selectedCure.cures[0].quantity
														}}
														text='myAccount.cures.remainingQuantity.label'
													/>
												</div>
											)}
									</>
								);
							})}
						</div>
						{displayFees && (
							<div className={styles.table}>
								<div>
									<span>
										<Localize text={'onlinePayment.userPaysFeesLabel'} />
									</span>
									<span className={styles.price}>
										{formatPrice(fees, true)}
									</span>
								</div>
								<hr />
							</div>
						)}
						{isDeposit && !useGiftVoucher && !useCure ? (
							<div className={styles.table}>
								<div className={styles.thead}>
									<span>
										<Localize text={'onlinePayment.total.default'} />
									</span>
									<span className={styles.price}>{totalPriceWithFees}</span>
								</div>
								{moveAppointment && hasSameAmount ? (
									<>
										<div className={styles.thead}>
											<span>
												<Localize
													args={{ date: paymentCreatedDateToDelete }}
													text={'onlinePayment.total.paid'}
												/>
											</span>
											<span>{totalPriceToDelete}</span>
										</div>
										<hr />
										<div className={styles.thead}>
											<span>
												<Localize text={'onlinePayment.total.restToPayLater'} />
											</span>
											<span>{depositRest}</span>
										</div>
									</>
								) : (
									<>
										<hr />
										<div className={styles.thead}>
											<span>
												<Localize text={'onlinePayment.details.depositValue'} />
											</span>
											<span>{depositPrice}</span>
										</div>
										<div>
											<span>
												<Localize text={'onlinePayment.details.onSiteValue'} />
											</span>
											<span>{depositRest}</span>
										</div>
									</>
								)}
								<hr />
							</div>
						) : (
							<div className={styles.table}>
								<div className={styles.thead}>
									<span>
										<Localize
											text={
												(moveAppointment && hasSameAmount) ||
												useGiftVoucher ||
												useCure
													? 'onlinePayment.total.default'
													: 'onlinePayment.total.toPay'
											}
										/>
									</span>
									<span
										className={styles.price}
										id='appointment-payment-total-price-toPay'
									>
										{useCure
											? totalPriceWithEligibleCures
											: this.props.totalPrice || totalPrice}
									</span>
								</div>
								{moveAppointment && hasSameAmount && (
									<div className={styles.thead}>
										<span>
											<Localize
												args={{ date: paymentCreatedDateToDelete }}
												text={'onlinePayment.total.paid'}
											/>
										</span>
										<span>{totalPriceToDelete}</span>
									</div>
								)}
								<hr />
								{/*The rest to pay if online appointment moved */}

								{moveAppointment && hasSameAmount ? (
									<>
										<div className={styles.thead}>
											<span>
												<Localize text={'onlinePayment.total.restToPay'} />
											</span>
											<span className={styles.price}>
												{formatPrice(0, true)}
											</span>
										</div>
										<hr />
									</>
								) : null}
							</div>
						)}
						{usePrePaymentWithCure &&
							isMoveAppointmentValidWithoutPrepaymentOrDeposit && (
								<div>
									<span className={styles.curePaymentLabel}>
										<Localize
											args={{
												count: eligibleCures.length > 1 ? 1 : 0
											}}
											text={'myAccount.cures.paymentTitle'}
										/>
									</span>
									<CurePaymentChoice
										allServicesAreCoveredByCures={allServicesAreCoveredByCures}
										eligibleCures={this.getUniqueFirstCures(eligibleCures)}
										isLoading={
											isPayAllOrDepositMethod
												? this.props.isLoading || !clientSecret
												: this.props.isLoading
										}
										paymentMethod={paymentMethod}
										setPaymentMethod={handleCureFocus}
									/>
									<hr />
								</div>
							)}

						{/*CANCELLATION POLICIES*/}
						{showCancellationPolicies && (
							<div>
								<span className={styles.label}>
									<Localize
										text={
											isPrepaymentOrDeposit
												? 'onlinePayment.cancel.policy.title'
												: 'onlinePayment.conditionsPaiement'
										}
									/>
								</span>
								<div>
									<span className={styles.precision}>
										{/*Only display charge if prePayment option is not available*/}
										{!isPrepaymentOrDeposit && (
											<Localize text={'onlinePayment.chargeEndOfAppointment'} />
										)}
									</span>
								</div>

								{this.policyTextDisplay({
									isPast: past,
									cancelFee,
									noShowFee,
									totalPriceWithoutFees,
									isPrePayment,
									isDeposit,
									date
								})}
								{(!moveAppointment || (moveAppointment && !hasSameAmount)) && (
									<hr />
								)}
							</div>
						)}
						{/* GIROPAY WARNING */}
						{/* remove it en 2024 : PLAN-14741 */}
						{business.countryCode === 'DE' && (
							<div>
								<Banner iconSize={20} status='INFORMATION_WARNING'>
									<p style={{ fontSize: 14, lineHeight: '18px' }}>
										<Localize text='bookAppointment.appointment.endGiropay.title' />
									</p>
									<p style={{ fontSize: 12, lineHeight: '16px' }}>
										<Localize text='bookAppointment.appointment.endGiropay.content' />
									</p>
								</Banner>
							</div>
						)}
						{((displayDepositPaymentChoice &&
							(paymentMethod === PAYMENT_METHOD.PAY_DEPOSIT ||
								paymentMethod === PAYMENT_METHOD.PAY_ALL)) ||
							isGiftVoucher ||
							!displayDepositPaymentChoice) &&
							isMoveAppointmentValidWithoutPrepaymentOrDeposit && (
								<StripePayment
									{...this.props}
									amount={stripePaymentAmount}
									amountWithoutFees={stripePaymentAmountWithoutFees}
									childId={childId}
									childName={childName}
									clientSecret={redirectClientSecret || clientSecret}
									confirm={
										isGiftVoucher
											? this.props.confirmGiftVoucher
											: this.props.confirm
									}
									countryCode={business.countryCode}
									displayAddCard={this.state.displayAddCard}
									formatAppointmentForLambda={formatAppointmentForLambda}
									formattedSteps={formattedSteps}
									isDeposit={
										hasDepositActivated
											? paymentMethod === PAYMENT_METHOD.PAY_ALL
												? 'full'
												: 'partial'
											: null
									}
									isLoading={this.props.isLoading}
									isPrePayment={isPrePayment || isGiftVoucher}
									resetPaymentData={this.props.resetPaymentData}
									resetPaymentIntent={resetPaymentIntent}
									setDisplayAddCard={displayAddCard => {
										this.setPaymentMethodIfDepositPayment(
											displayDepositPaymentChoice,
											setPaymentMethod,
											PAYMENT_METHOD
										);
										this.setState({ displayAddCard });
									}}
									setError={setError}
									setIsLoading={setIsLoading}
									steps={JSON.stringify(onlinePaymentServices)}
									totalPrice={this.props.totalPrice || totalPrice}
									totalPriceWithoutFees={totalPriceWithoutFees}
									userPaysFees={userPaysFees}
								/>
							)}

						{/* GIFT VOUCHER INPUT */}
						{displayGiftVoucherInput &&
							isMoveAppointmentValidWithoutPrepaymentOrDeposit && (
								<GiftVoucherPayment
									{...this.state.giftVoucher}
									giftVoucherPaymentInfo={giftVoucherPaymentInfo}
									paymentMethod={paymentMethod}
									onClickDelete={handleGiftVoucherDeletion}
									onClickValidate={handleGiftVoucherValidation}
									onFocus={handleGiftVoucherInputFocus}
									onVoucherNumberChange={handleGiftVoucherInputChange}
								/>
							)}

						{!this.state.displayAddCard && renderPaymentConfirmationButton}
						{paymentMethodType === PAYMENT_TYPE_CARD && (
							<Policies isGiftVoucher={isGiftVoucher} locale={locale} />
						)}
					</div>
				) : (
					<div className={styles.spinner}>
						<Spinner />
					</div>
				)}
			</div>
		);
	}
	setPaymentMethodIfDepositPayment = (
		displayDepositPaymentChoice,
		setPaymentMethod,
		PAYMENT_METHOD
	) => {
		if (!displayDepositPaymentChoice) {
			setPaymentMethod(PAYMENT_METHOD.PAY_ALL);
		}
	};

	getUniqueFirstCures = eligibleCures => {
		const displayedCures = new Set();
		const uniqueCures = [];
		eligibleCures.forEach(({ cures }) => {
			const firstCure = cures[0];
			if (!displayedCures.has(firstCure.id)) {
				displayedCures.add(firstCure.id);
				uniqueCures.push(firstCure);
			}
		});
		return uniqueCures;
	};

	policyTextDisplay = ({
		isPast,
		cancelFee,
		noShowFee,
		totalPriceWithoutFees,
		isPrePayment,
		isDeposit,
		date
	}) => {
		const { business, dateLocale } = this.props;
		// date-fns doesn't hande 'null' value so send default date in case of date is null
		const dateForFormat = date || new Date();
		const formattedDate = format(
			subHours(
				dateForFormat,
				business.settings.onlinePayment.lateCancellationDelay
			),
			'dd MMMM',
			{ locale: dateLocale }
		);
		const formattedHours = format(
			subHours(
				dateForFormat,
				business.settings.onlinePayment.lateCancellationDelay
			),
			'HH:mm',
			{ locale: dateLocale }
		);
		const tradKeyword = isPrePayment
			? FULL_DEPOSIT_AND_PREPAYMENT
			: isDeposit
			? PARTIAL_DEPOSIT
			: CLASSIC_ONLINE_PAYMENT;

		const displayedCancelFees = formatPrice(
			(isPrePayment || isDeposit
				? totalPriceWithoutFees / 100 - cancelFee
				: cancelFee) * 100,
			true
		);

		const displayedNoShowFees = formatPrice(
			(isPrePayment || isDeposit
				? totalPriceWithoutFees / 100 - noShowFee
				: noShowFee) * 100,
			true
		);

		return (
			<div>
				{isPast && cancelFee > 0 && (
					<div
						className={styles.precision}
						id={`onlinePayment.lateCancellation.${tradKeyword}`}
					>
						<Localize
							args={{ amount: displayedCancelFees }}
							text={`onlinePayment.lateCancellation.${tradKeyword}`}
						/>
					</div>
				)}
				{!isPast && cancelFee > 0 && (
					<div
						className={styles.precision}
						id={`onlinePayment.cancellation.${tradKeyword}`}
					>
						<Localize
							args={{
								date: formattedDate,
								hour: formattedHours,
								amount: displayedCancelFees
							}}
							text={`onlinePayment.cancellation.${tradKeyword}`}
						/>
					</div>
				)}
				{(isPrePayment || isDeposit) && cancelFee === 0 && (
					<div
						className={styles.precision}
						id={'onlinePayment.cancel.policy.cancelRateNull'}
					>
						<Localize text={'onlinePayment.cancel.policy.cancelRateNull'} />
					</div>
				)}
				{!isPrePayment && !isDeposit && cancelFee === 0 && (
					<div
						className={styles.precision}
						id={'onlinePayment.annulation.cancelRateNull'}
					>
						<Localize text={'onlinePayment.annulation.cancelRateNull'} />
					</div>
				)}
				{noShowFee > 0 && (
					<div
						className={styles.precision}
						id={`onlinePayment.noShow.${tradKeyword}`}
					>
						<Localize
							args={{ amount: displayedNoShowFees }}
							text={`onlinePayment.noShow.${tradKeyword}`}
						/>
					</div>
				)}
			</div>
		);
	};

	calculateCancelFee = (isPrePayment, userPaysFees, stripeFees) => {
		const { onlinePaymentServices } = this.props;
		const cancelFee =
			onlinePaymentServices.isOnline
				.concat(onlinePaymentServices.remaining)
				.reduce((prix, service) => {
					let price = 0;
					if (service.prices && service.prices.default !== 0) {
						price = service.prices.default
							? service.prices.default
							: service.prices.min;
					}

					if (price) {
						return prix + (price * service.cancelRate) / 100;
					}

					return prix;
				}, 0) / 100;
		const fees =
			computeFees({
				amount: cancelFee * 100,
				stripeFees
			}) / 100;

		return userPaysFees && ENABLE_USER_PAYS_FEES && !isPrePayment
			? cancelFee + fees
			: cancelFee;
	};

	calculateNoShowFee = (isPrePayment, userPaysFees, stripeFees) => {
		const { onlinePaymentServices } = this.props;
		const noShowFee =
			onlinePaymentServices.isOnline
				.concat(onlinePaymentServices.remaining)
				.reduce((prix, service) => {
					let price = 0;
					if (service.prices && service.prices.default !== 0) {
						price = service.prices.default
							? service.prices.default
							: service.prices.min;
					}

					if (price) {
						return prix + (price * service.noshowRate) / 100;
					}

					return prix;
				}, 0) / 100;
		const fees =
			computeFees({
				amount: noShowFee * 100,
				stripeFees
			}) / 100;

		return userPaysFees && ENABLE_USER_PAYS_FEES && !isPrePayment
			? noShowFee + fees
			: noShowFee;
	};

	confirm = async hasSameAmount => {
		const {
			isGiftVoucher,
			stripe,
			confirmGiftVoucher,
			confirm: confirmAppointment,
			selectedPaymentMethod,
			isPrepayment,
			clientSecret,
			setError,
			setIsLoading,
			moveAppointment,
			stripeCountryCode,
			userId,
			updateConsentPaymentMethod,
			doesSelectedPaymentMethodNeedConsent,
			isSelectedPaymentLinkedToActiveAppointments,
			paymentMethodType
		} = this.props;
		if (!stripe) {
			//TODO good error translation
			return setError('defaultError');
		}

		setIsLoading(true);

		let result;
		if (!moveAppointment || (moveAppointment && !hasSameAmount)) {
			//cas cures
			if (this.props.paymentMethod === PAYMENT_METHOD.USE_CURE) {
				return confirmAppointment({ isBookedWithCure: true });
			}
			//cas carte cadeau
			if (this.props.paymentMethod === PAYMENT_METHOD.USE_GIFT_VOUCHER) {
				const giftVoucherNumber = parseInt(
					this.state.giftVoucher.giftVoucherNumber
				);
				return confirmAppointment({ giftVoucherNumber });
			}

			//cas empreinte
			else if (!isGiftVoucher && !isPrepayment) {
				result = await stripe.confirmCardSetup(clientSecret, {
					payment_method: selectedPaymentMethod.id
				});
				if (result.error) {
					setIsLoading(false);
					return isStripeError(result)
						? setError(
								result.error.decline_code?.toUpperCase() ||
									result.error.code.toUpperCase()
						  )
						: setError('defaultError');
				}
				return confirmAppointment();
			}

			//cas paymentIntent (pré paiement ou cc)
			else {
				result = await stripe.confirmCardPayment(clientSecret, {
					payment_method: selectedPaymentMethod.id
				});
			}

			if (result.error) {
				setIsLoading(false);
				return isStripeError(result)
					? setError(
							result.error.decline_code?.toUpperCase() ||
								result.error.code.toUpperCase()
					  )
					: setError('defaultError');
			}

			if (
				paymentMethodType === PAYMENT_TYPE_CARD &&
				doesSelectedPaymentMethodNeedConsent &&
				!isSelectedPaymentLinkedToActiveAppointments
			) {
				updateConsentPaymentMethod(stripeCountryCode, userId);
			}
		}

		if (isGiftVoucher) {
			return confirmGiftVoucher({
				paymentIntentId: result?.paymentIntent?.id || null
			});
		}

		if (isPrepayment) {
			return confirmAppointment({
				paymentIntentId: result?.paymentIntent?.id || null,
				hasSameAmount
			});
		}
	};
}

export default withStripeElementsConsumer(
	withStripeFees(withLocalization(withStyles(styles)(OnlinePayment)))
);
